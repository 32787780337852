/* ***
 * 20231011: created by cy
 * 前端日志统一解决方案：error 级别以上的前端错误发送到服务端进行前端日志记录
 * logLevel: info, warn, error, fatal 4种，将消息发服务端记录，其它只在前台控制台进行输出
 * 20240810: 改-> 所有级别都送服务端记录
 * 
 * 在需要该功能的 前端 js 页面中，API调用方法如下：
 * 引入该功能：
 * import { logToServerAndConsole } from './logToConsoleAndServer.js';
 * 在需要记录日志的地方调用函数
 * logToServerAndConsole('info', 'This is an info message.');
 */
// export function logToServerAndConsole(logLevel, message) {
//  20241004: ES6 风格的改造
// function logToServerAndConsole(logLevel, message, actualUrl) {
export function logToServerAndConsole(logLevel, message, actualUrl) {
    // 输出到控制台
    console.log(message);
  
    // Ajax 发送请求数据
    // 发送日志到服务器（仅当日志级别为error或以上时发送）
    // loglevel：大小写问题！！！
    // if (logLevel && (logLevel === 'error' || logLevel === 'warn' || loglevel === 'fatal')) {
    // if (logLevel && (logLevel === 'error' || logLevel === 'warn' || logLevel === 'fatal')) {
        const xhr = new XMLHttpRequest();
        // 设置xhr的错误处理函数
        xhr.onerror = function () {
            console.error('请求发生错误');
            console.error('请求发生错误，状态码：', xhr.status);
            console.error('请求发生错误，状态描述：', xhr.statusText);
            console.error('请求发生错误，错误详情：', xhr.responseText); // 输出具体的错误响应内容
        };
        xhr.open('POST', '/frontLog', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify({ logLevel, message, actualUrl }));
        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
                if (xhr.status >= 200 && xhr.status < 300) {
                    // 请求成功
                    console.log('前端日志发送成功！');
                    // 这里可以处理成功的逻辑，如果需要的话
                } else {
                    // 请求失败
                    console.error('前端日志发送失败，状态码：', xhr.status);
                    // 这里可以处理失败的逻辑，如果需要的话
                }
            }
        }
    // }
};
// 20241004：后期项目完全改造成 ES6 风格后，这句可以去掉了
window.logToServerAndConsole = logToServerAndConsole;