/* 20241004: 将纯 javascript JS文件代码中定义的需要全局共享的函数、变量挂载到 页面的 window 对象，
 * 挂载后，可以让其它的模块使用它们。
 * webpackage 打包之后的需要！否则打包后的各个 JS 之间无法互通 。
 * 各个 JS 代码文件中需要引入这个模块，提供参数给 mountGlobalObjects ，完成挂载操作
 * module: 操作的是那个模块
 * globalShareObjects: 模块中的函数和变量数据，数据结构：
 * const globalShareObjects = {
        functions: {
            commonHeadInit,
            getmineInfo,
            ensureBrotliModule,
            useBrotliCompress,
            useFetchAndDecompress
        },
        variables: {
            globalVariables,
            countdownTime,
            currentURL,
            currentPage,
            searchKeyword,
            pageRefreshLoginStatus
        }
    };
 * 
 */ 
import { logToServerAndConsole } from './logToConsoleAndServer.js';

// console.log('mountGlobalObjects.js loaded');  //调测

export function mountGlobalObjects(module, globalShareObjects) {

    // logToServerAndConsole('info', `${module}: Starting to mount global variables and functions...`);
    if ( globalShareObjects.functions && Object.keys(globalShareObjects.functions).length === 0 && 
         globalShareObjects.variables && Object.keys(globalShareObjects.variables).length === 0){
        logToServerAndConsole('info', `${module}:  None global variables and functions to mount`);   
        return;
    }
    
    // 这两句生产环境时去掉！
    // console.log('globalShareObjects.functions: ', globalShareObjects.functions);
    // console.log('globalShareObjects.variables: ', globalShareObjects.variables);

    Object.keys(globalShareObjects.functions).forEach(funcName => {
        if (typeof globalShareObjects.functions[funcName] === 'function') {
            window[funcName] = globalShareObjects.functions[funcName];
        } else {
            logToServerAndConsole('warn', `${module} 模块挂载函数到 window 错误， 未定义的函数：${funcName}`);
        }
    });

    // 20241114: 问题--对象型变量可以正确挂载和修改，原始类型变量因为不是引用关系修改操作反射不到挂载对象上。
    // 将原始类型的变量，在挂载操作时封装成对象，确保引用关系。然后在页面上的其它代码中通过
    //  window[varName].gsoValue / varName.gsoValue 来访问原始值，确保引用一致性。 gsoValue: globalShareObjects value
    // Object.keys(globalShareObjects.variables).forEach(varName => {
    //     if (globalShareObjects.variables[varName] !== undefined) {
    //         window[varName] = globalShareObjects.variables[varName];
    //     } else {
    //         logToServerAndConsole('warn', `${module} 模块挂载变量到 window 对象发生错误，变量名：${varName}, 值: ${globalShareObjects.variables[varName]}`);
    //         window[varName] = null;  // 可以设置为 null，或者保留 undefined
    //     }
    // });
    Object.keys(globalShareObjects.variables).forEach(varName => {
        const varValue = globalShareObjects.variables[varName];
    
        if (varValue !== undefined) {
            if (Array.isArray(varValue)) {
                // 如果是数组，直接挂载到 window
                window[varName] = varValue;  // 直接使用原数组，保持引用一致性
            } else if (typeof varValue !== 'object' || varValue === null) {
                // 如果是原始类型（string, number, boolean 等）或 null，封装成对象
                window[varName] = { gsoValue: varValue };
            } else {
                // 如果是普通对象，直接挂载
                window[varName] = varValue;
            }
        } else {
            // 如果值是 undefined，初始化为空对象
            logToServerAndConsole('warn', `${module} 模块挂载变量到 window 对象，变量名：${varName}, 值: ${varValue}, -> 初始化：{}`);
            window[varName] = {};  // 默认对象值
        }
    });
    
    logToServerAndConsole('info', `${module}: Finished mounting global variables and functions.`);
}
// 20241115：后期项目完全改造成 ES6 风格后，这句可以去掉了
window.mountGlobalObjects = mountGlobalObjects;